<template>
    <div class="event-card-wrapper" @click="$emit('click', $event)">
        <div class="event-card-small" :class="classes">
            <Badge :label="toDaysLeft" size="small" :color="!primary ? 'yellow' : 'primary'" />
            <h1 class="event-card-small__weekday font-weight-medium">
                {{ daysUntilStart > 7 ? startDateShort : startDateDay }}
            </h1>
            <h3 class="event-card-small__name font-weight-medium">
                {{ event.name }}
            </h3>
            <small>Event</small>
            <h2 class="event-card-small__time">
                {{ startTime + ' - ' + endTime }}
            </h2>
            <div class="event-card-small__created-by font-weight-medium">
                <img class="avatar-sm" :src="event.fan.avatar" />
                <p>{{ event.fan.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Badge from '@/components/Badge'
import { differenceInDays, isToday, format } from 'date-fns'
export default {
    name: 'EventCardSmall',
    components: {
        Badge
    },
    props: {
        event: {
            type: Object,
            required: true
        },
        primary: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    computed: {
        classes() {
            return {
                'event-card-small--primary': this.primary
            }
        },
        eventStartDateObject() {
            return new Date(this.event.eventStart)
        },
        daysUntilStart() {
            return differenceInDays(new Date(this.event.eventStart), new Date())
        },
        startDateShort() {
            return format(new Date(this.event.eventStart), 'dd LLL')
        },
        startDateDay() {
            return format(new Date(this.event.eventStart), 'EEEE')
        },
        startTime() {
            return this.formatTime(new Date(this.event.eventStart))
        },
        endTime() {
            return this.formatTime(new Date(this.event.eventEnd))
        },
        toDaysLeft() {
            if (isToday(this.eventStartDateObject)) {
                return 'Today'
            } else {
                return differenceInDays(this.eventStartDateObject, new Date()) + 1 + ' days left'
            }
        }
    },
    methods: {
        formatTime(date) {
            return format(date, 'HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.event-card-wrapper {
    display: inline-block;
    cursor: pointer;
}

.event-card-small {
    padding: 1rem;
    background: #fff;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    display: grid;
    grid-template-rows: repeat(1fr, 6);
    overflow: hidden;

    :first-child {
        justify-self: end;
    }

    &__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__time {
        font-size: 1.125rem;
    }

    &__created-by {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        justify-items: start;
        column-gap: 0.5rem;
    }
}
.event-card-small--primary {
    background: $primary;
    color: $beige;
}
</style>
