import gql from 'graphql-tag'

import {
    basicArtistFragment,
    basicFanFragment,
    basicVenueFragment,
    addressFragment
} from '../fragments/fragments.js'

export const EVENTREQUEST = gql`
    query EventRequest($id: ID!) {
        eventrequest(id: $id) {
            id
            name
            price
            requeststatus
            status
            venue {
                id
            }
            conversation {
                id
            }
            event {
                id
                eventStart
                eventEnd
                name
                fan {
                    id
                    media {
                        id
                        profile {
                            id
                            url
                        }
                    }
                    avatar @client
                }
                ${addressFragment}
                venue {
                    id
                    name
                }
                locationText @client
            }
        }
    }
`

export const MY_EVENTREQUESTS = gql`
    query MyEventRequests($where: JSON, $type: eventRequestType) {
        myeventRequests(where: $where, type: $type) {
            id
            status
            statusText @client
            name
            createdAt
            updatedAt
            requeststatus
            ${basicArtistFragment}
            ${basicVenueFragment}
            event {
                id
                name
                eventStart
                eventEnd
                status
                statusText @client
                updatedAt
                createdAt
                ${basicFanFragment}
                ${addressFragment}
                locationText @client
                venue {
                    id
                    name
                }
            }
        }
    }
`
