import gql from 'graphql-tag'

import {
    addressFragment,
    basicFanFragment,
    basicArtistFragment,
    basicVenueFragment
} from '../fragments/fragments.js'

export const EVENT = gql`
    query Event($id: ID!) {
        event(id: $id) {
            id
            name
            createdAt
            updatedAt
            description
            eventStart
            eventEnd
            status
            location
            locationText @client
            venue {
                id
                name
            }
            ${addressFragment}
            ${basicFanFragment}
        }
    }
`

export const MY_EVENT = gql`
    query MyEvent($id: ID!) {
        myEvent(id: $id) {
            id
            name
            createdAt
            updatedAt
            description
            eventStart
            eventEnd
            participantsmin
            participantsmax
            budgetmin
            budgetmax
            status
            statusText @client
            locationText @client
            location
            totalPrice
            paymentStatus
            ${basicVenueFragment}
            ${basicArtistFragment}
            eventrequests {
                id
                createdAt
                updatedAt
                name
                desc
                status
                statusText @client
                requeststatus
                ${basicArtistFragment}
                ${basicVenueFragment}
            }
            ${addressFragment}
            ${basicFanFragment}
        }
    }
`

export const MY_EVENTS = gql`
    query MyEvents ($where: JSON, $sort: String, $start: Int, $limit: Int, $publicationState: PublicationState){
        myEvents (where: $where, sort: $sort, start: $start, limit: $limit, publicationState: $publicationState){
            id
            createdAt
            updatedAt
            name
            description
            eventStart
            eventEnd
            participantsmin
            participantsmax
            budgetmin
            budgetmax
            location
            status
            statusText @client
            locationText @client
            totalPrice
            paymentStatus
            ${basicArtistFragment}
            ${basicVenueFragment}
            ${addressFragment}
            eventrequests {
                id
                createdAt
                updatedAt
                name
                desc
                status
                statusText @client
                requeststatus
                ${basicArtistFragment}
                ${basicVenueFragment}
            }
        }
    }
`

export const UPCOMING_EVENTS = gql`
    query UpcomingEvents(
        $where: JSON
        $sort: String
        $limit: Int
        $start: Int
    ) {
        upcomingEvents(
            where: $where
            sort: $sort
            limit: $limit
            start: $start
        ) {
            id
            name
            status
            eventStart
            eventEnd
            image {
                id
                url
            }
            ${basicFanFragment}
            venue {
                id
            }
            artist {
                id
            }
            location
        }
    }
`

export const FIND_EVENT_VENUE = gql`
    query FindEventVenue($where: VenueSuggestionQueryType) {
        findEventVenue(where: $where) {
            id
            name
            location
            categories
            capacity
            calcDistance
            media {
                id
                profile {
                    id
                    url
                }
            }
            avatar @client
        }
    }
`

export const FIND_EVENT_ARTIST = gql`
    query FindEventArtist($limit: Int, $where: ArtistSuggestionQueryType) {
        findEventArtist(limit: $limit, where: $where) {
            id
            name
            location
            categories
            calcDistance
            media {
                id
                profile {
                    id
                    url
                }
            }
            avatar @client
        }
    }
`
