<template>
    <div class="calendar-wrapper text-primary bg-light">
        <div ref="monthScroll" class="month-select bg-white">
            <div
                v-for="(month, index) in getMonths"
                :key="month + index"
                :ref="index"
                class="month-select__button"
                :class="{ 'month-select__button--active': index === selectedMonth }"
                @click="selectedMonth = index"
            >
                {{ month }}
            </div>
        </div>

        <div class="calendar">
            <img
                v-if="$mq !== 'sm'"
                src="@/assets/icons/chevron_left_icon.svg"
                alt=""
                :style="{ cursor: 'pointer' }"
                @click="selectedMonth = Math.max(0, --selectedMonth)"
            />
            <Calendar :month="selectedMonth" :events="getContent" />
            <img
                v-if="$mq !== 'sm'"
                src="@/assets/icons/chevron_right_icon.svg"
                alt=""
                :style="{ cursor: 'pointer' }"
                @click="selectedMonth = Math.min(getMonths.length - 1, ++selectedMonth)"
            />
        </div>
        <LoadingEllipsis v-if="$apollo.loading">
            <span :style="{ fontSize: '14px', padding: '1rem' }">Fetching events</span>
        </LoadingEllipsis>
        <div class="event-card-list">
            <router-link
                v-for="event in getContent"
                :key="event.id"
                v-slot="{ navigate }"
                :to="event.link"
            >
                <EventCardSmall
                    :id="event.id + 'test'"
                    :event="event"
                    :primary="event.status === 'COMPLETE'"
                    @click="navigate"
                />
            </router-link>
        </div>
    </div>
</template>

<script>
import EventCardSmall from '@/components/EventCardSmall'
import Calendar from '@/components/Calendar'
import { MY_EVENTREQUESTS } from '@/graphql/queries/eventRequest.js'
import { MY_EVENTS } from '@/graphql/queries/event'

import { months } from '@/utils/dateUtils'
export default {
    components: {
        EventCardSmall,
        Calendar
    },
    inject: ['getCurrentProfileType', 'getMyCurrentProfile'],
    apollo: {
        myEventRequests: {
            query: MY_EVENTREQUESTS,
            variables: {
                where: {
                    event: {
                        eventStart_gte: new Date().toISOString()
                    }
                }
            },
            update({ myeventRequests }) {
                return myeventRequests
            }
        },
        myEvents: {
            query: MY_EVENTS,
            variables: {
                where: {
                    eventStart_gt: new Date().toISOString()
                },
                sort: 'eventStart:ASC'
            }
        }
    },
    data() {
        return {
            myEventRequests: [],
            myEvents: [],
            selectedMonth: new Date().getMonth()
        }
    },
    computed: {
        getMonths() {
            return months
        },
        getContent() {
            switch (this.getCurrentProfileType()) {
                case 'fan':
                    return this.myEvents.map((event) => ({
                        ...event,
                        fan: this.getMyCurrentProfile(),
                        link: `/events/event/${event.id}`
                    }))
                case 'artist':
                case 'venue':
                    return this.myEventRequests
                        .filter((req) => {
                            const requestee = req.artist || req.venue
                            return requestee.id === this.getMyCurrentProfile().id
                        })
                        .sort((b, a) => new Date(b.event.eventStart) - new Date(a.event.eventStart))
                        .map((req) => ({ ...req.event, link: `/request/${req.id}` }))
                default:
                    throw new Error('No matching profile type')
            }
        }
    },
    created() {
        // Scroll the current month into view after first render
        this.$nextTick(() => {
            const month = new Date().getMonth()
            const currentMonthButton = this.$refs[month][0]
            this.$refs.monthScroll.scrollLeft = currentMonthButton.offsetLeft
        })
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.calendar-wrapper {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 110px;
    .month-select {
        background: white;
        overflow: auto;
        max-width: 100vw;
        display: grid;
        justify-content: start;
        grid-auto-flow: column;
        grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));
        &__button {
            border-bottom: 2px solid white;
            padding: 14px 16px;
            cursor: pointer;
            &--active {
                transition: all 0.5s ease;
                border-bottom: 2px solid $primary;
            }
        }
    }

    .calendar {
        display: grid;
        grid-template-rows: auto 1fr;
        font-size: 16px;
        .weekdays {
            padding: 10px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            justify-items: center;
        }

        .days {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            place-items: center;

            padding: 10px;
            * {
                width: 30px;
                height: 30px;
                display: grid;
                place-items: center;
                border-radius: 40px;
            }
            .active {
                background: $beige;
            }
        }
    }

    .event-card-list {
        width: 100vw;
        padding: 0.5rem;
        white-space: nowrap;
        overflow: auto;
        scroll-behavior: smooth;
        .event-card-wrapper + .event-card-wrapper {
            margin-left: 0.5rem;
        }
    }
}
@media only screen and (min-width: 768px) {
    /* For desktop: */
    .events-container {
        // height: 100%;
        // &__nav {
        //     justify-content: center;
        // }
    }
    .calendar-wrapper {
        // display: grid;
        // grid-template-columns: auto 1fr auto;
        // align-items: center;
        width: auto;
        // margin: auto;
        .month-select {
            width: 100%;
        }
    }
}
</style>
