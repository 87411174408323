<template>
    <div class="calendar bg-white">
        <div class="calendar__weekdays">
            <small v-for="weekday in weekdays" :key="weekday.name">
                {{ weekday.short }}
            </small>
        </div>

        <div class="calendar__dates">
            <span
                v-for="(day, index) in days"
                :key="'day' + day"
                class="calendar__date"
                :style="[index === 0 ? { gridColumnStart: getFirstDayOfTheMonth } : {}]"
                :class="{
                    'calendar__date--today': isToday(day),
                    'calendar__date--confirmed': hasConfirmedEvent(day),
                    'calendar__date--unconfirmed': hasEvent(day)
                }"
            >
                <a
                    v-if="hasEvent(day) || hasConfirmedEvent(day)"
                    @click.prevent="onDateClick(getFirstEvent(day).id)"
                    >{{ day }}</a
                >
                <span v-else>
                    {{ day }}
                </span>
                <span
                    v-if="hasNotch(day)"
                    class="calendar__date-notch"
                    :class="{
                        'calendar__date-notch--primary': isNotchPrimary(day)
                    }"
                >
                    {{ eventsPerDayCount(day) }}
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { getDaysInMonth, getDay, isToday as _isToday, isSameDay as _isSameDay } from 'date-fns'

export default {
    name: 'Calendar',
    props: {
        month: {
            type: Number,
            default: 10 // January
        },
        year: {
            type: Number,
            default: new Date().getFullYear() // This year
        },
        events: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            weekdays: [
                {
                    name: 'Monday',
                    short: 'M',
                    numeric: 1
                },
                {
                    name: 'Tuesday',
                    short: 'T',
                    numeric: 2
                },
                {
                    name: 'Wednesday',
                    short: 'W',
                    numeric: 3
                },
                {
                    name: 'Thursday',
                    short: 'T',
                    numeric: 4
                },
                {
                    name: 'Friday',
                    short: 'F',
                    numeric: 5
                },
                {
                    name: 'Saturday',
                    short: 'S',
                    numeric: 6
                },
                {
                    name: 'Sunday',
                    short: 'S',
                    numeric: 7
                }
            ]
        }
    },
    computed: {
        // Get first day of the month (Monday = 1, Tuesday = 2, ..., Sunday = 7)
        getFirstDayOfTheMonth() {
            let d = getDay(new Date(this.year, this.month))
            if (d === 0) {
                return 7
            }
            return d
        },
        // Get array of numbers from 1 to end-of-month
        days() {
            const days = getDaysInMonth(new Date(this.year, this.month))
            return new Array(days).fill().map((_, i) => i + 1)
        }
    },
    methods: {
        onDateClick(eventId) {
            window.location.hash = eventId
        },
        hasConfirmedEvent(day) {
            return this.events.some(
                (event) =>
                    this.isSameDay(new Date(event.eventStart), day) && event.status === 'COMPLETE'
            )
        },
        hasEvent(day) {
            return this.events.some(
                (event) =>
                    this.isSameDay(new Date(event.eventStart), day) && event.status !== 'COMPLETE'
            )
        },
        isToday(day) {
            return _isToday(new Date(this.year, this.month, day))
        },
        isSameDay(date, day) {
            return _isSameDay(new Date(this.year, this.month, day), date)
        },
        isNotchPrimary(day) {
            return this.getEventsPerDay(day).every((event) => event.status === 'COMPLETE')
        },
        hasNotch(day) {
            return this.eventsPerDayCount(day) > 1
        },
        eventsPerDayCount(day) {
            return this.getEventsPerDay(day).length
        },
        getEventsPerDay(day) {
            return this.events.filter((event) => this.isSameDay(new Date(event.eventStart), day))
        },
        getFirstEvent(day) {
            return this.events.find((event) => this.isSameDay(new Date(event.eventStart), day))
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.calendar {
    display: grid;
    grid-template-rows: auto 1fr;
    font-size: 16px;
    margin: 10px;
    &__weekdays {
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        justify-items: center;
    }

    &__dates {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        place-items: center;

        padding: 0 1rem 1rem;
        row-gap: 0.5rem;

        .primary {
            background: $primary;
            color: white;
        }
    }

    &__date {
        height: 2rem;
        width: 2rem;
        display: grid;
        place-items: center;
        border-radius: 50%;
        font-weight: 500;
        position: relative;

        &--today {
            background: $beige;
        }

        &--unconfirmed {
            background: $yellow;
            color: white;
        }
        &--confirmed {
            background-color: $primary;
            color: $beige;
        }

        &--unconfirmed,
        &--confirmed {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__date-notch {
        position: absolute;
        top: -6px;
        right: -6px;
        background: $yellow;
        color: $white;
        width: 1rem;
        height: 1rem;
        display: grid;
        place-items: center;
        font-size: 0.8rem;
        line-height: 1rem;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px white;

        &--primary {
            background-color: $primary;
            color: $beige;
        }
    }
}
</style>
