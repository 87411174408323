<template>
    <span :class="classes">
        {{ label }}
    </span>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        label: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: 'medium',
            validator: function(value) {
                return ['small', 'medium', 'large'].indexOf(value) !== -1
            }
        }
    },
    computed: {
        classes() {
            return {
                'gb-badge': true,
                [`gb-badge--${this.color}`]: true,
                [`gb-badge--${this.size}`]: true,
                capitalize: true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.gb-badge {
    border-radius: 5px;
}

.gb-badge--small {
    font-size: 12px;
    padding: 0rem 0.5rem;
    height: 15px;
    display: flex;
    align-items: center;
    border-radius: 3px;
}

.gb-badge--medium {
    font-size: 14px;
    padding: 0.1rem 0.5rem;
    line-height: 1.3;
}

.gb-badge--large {
    font-size: 1rem;
    padding: 0.1rem 0.6rem;
    line-height: 1.5;
}

.gb-badge--primary {
    color: $beige;
    background-color: $primary;
}
.gb-badge--beige {
    color: $primary;
    background-color: $beige;
}

.gb-badge--yellow {
    color: #fff;
    background-color: $yellow;
}
.gb-badge--red {
    color: #fff;
    background-color: $red;
}

.gb-badge--gray {
    color: #fff;
    background-color: lightgrey;
}

.gb-badge--green {
    color: #fff;
    background-color: $green;
}
.gb-badge--white {
    color: $primary;
    background: #fff;
}
</style>
