import {
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    format
} from 'date-fns'

export const formatDate = (date) => {
    return format(date, 'dd/MM/yy')
}

export const formatTime = (date) => {
    return format(date, 'HH:mm')
}
export const toTimeSinceString = (date) => {
    const now = new Date()
    const diff = differenceInSeconds(now, date) // in seconds
    if (diff < 60) {
        return 'Less than a minute ago'
    }
    if (diff < 3600) {
        // less than an hour ago
        return `${differenceInMinutes(now, date)} minutes ago`
    }
    if (diff < 86400) {
        // less than a day ago
        return `${differenceInHours(now, date)} hours ago`
    } else {
        return `${differenceInDays(now, date)} days ago`
    }
}

export const toTimeSinceStringShort = (date) => {
    const now = new Date()
    const diff = differenceInSeconds(now, date) // in seconds
    if (diff < 60) {
        return 'Now'
    }
    if (diff < 3600) {
        // less than an hour ago
        return `${differenceInMinutes(now, date)} min`
    }
    if (diff < 86400) {
        // less than a day ago
        return `${differenceInHours(now, date)} hours`
    } else {
        return `${differenceInDays(now, date)} days`
    }
}
export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]
